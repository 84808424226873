<template>
    <main class="communication-new blank-aside-js page_bg_grey" :class = "(isOpenAside=='true')?'blank-aside':''">
        <banner-section location="communications_home-top"/>
        <section v-if="Array.isArray(prepaidSuppliers) && prepaidSuppliers.length" class="communication__brands">
            <div v-for="(supplier_item, index) in prepaidSuppliers" :key="index" :style="{'background-color': supplier_item.logo_background_color}" class="brand communication__brand">
                <router-link :to="{name: 'CommunicationSupplier', params: {id: supplier_item.id }}" tag="a" class="link brand__link" exact>
                    <img v-if="supplier_item && supplier_item.hasOwnProperty('image') && supplier_item.image" class="brand__img communication__img" :src="supplier_item.image" alt="">
                    <b 
                        v-if="supplier_item && supplier_item.hasOwnProperty('name') && supplier_item.name" 
                        class="text brand__text communication__brand-text"
                        :style="{'color': supplier_item.font_color}"
                    >
                        {{ supplier_item.name }}
                    </b>
                </router-link>
            </div>

        </section>

        <section v-if="Array.isArray(prepaidSuppliersWithBestsellers) && prepaidSuppliersWithBestsellers.length" class="communication__bestsellers">

            <h2 class="title communication__title">{{ $t("COMMUNICATION.BEST_SELLERS") }}</h2>

            <div class="communication__slider">

                <div class="communication__wrapper owl-carousel js-communication-bestsellers">

                    <div v-for="(supplier_item, supplier_index) in prepaidSuppliersWithBestsellers" :key="supplier_index" class="category-slider ">
                        <router-link :to="{name: 'CommunicationSupplier', params: {id: supplier_item.id }}" tag="a" class="link" exact>
                            <div class="category-slider__img-wrapper" :style="{'background-color': supplier_item.logo_background_color}">
                                <p :style="{'color': supplier_item.font_color}">{{ supplier_item.name }}</p>
                                <img class="category-slider__img" :src="supplier_item.image" alt="">
                            </div>
                        </router-link>

                        <div class="category-slider__info-block">

                            <div v-for="(bestseller, bestseller_index) in supplier_item.bestsellers" :key="bestseller_index" class="category-slider__info">

                                <router-link :to="{name: 'CommunicationProduct', params: {id: bestseller.id, supplier_id: bestseller.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name">
                                        {{ bestseller.name }}
                                    </p>

                                    <div v-if="bestseller.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + bestseller.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            ₪
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>

        <section v-if="Array.isArray(prepaidSuppliersWithSpecials) && prepaidSuppliersWithSpecials.length" class="communication__special">

            <h2 class="title communication__title">{{ $t("TOURISM.PROMOTIONS") }}</h2>

            <div class="communication__slider">

                <div class="communication__wrapper owl-carousel js-communication-special">
                    <div v-for="(supplier_item, supplier_index) in prepaidSuppliersWithSpecials" :key="supplier_index"  class="category-slider">
                        <router-link :to="{name: 'CommunicationSupplier', params: {id: supplier_item.id }}" tag="a" class="link" exact>
                            <div class="category-slider__img-wrapper" :style="{'background-color': supplier_item.logo_background_color}">
                                <p :style="{'color': supplier_item.font_color}">{{ supplier_item.name }}</p>
                                <img class="category-slider__img" :src="supplier_item.image" alt="">
                            </div>
                        </router-link>
                        <div class="category-slider__info-block">

                            <div v-for="(special, special_index) in supplier_item.specials" :key="special_index" class="category-slider__info">

                                <router-link :to="{name: 'CommunicationProduct', params: {id: special.id, supplier_id: special.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name">
                                        {{ special.name }}
                                    </p>

                                    <div v-if="special.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + special.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            ₪
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>
    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '../../appConfig'
import BannerSection from "../../components/BannerSection";

export default {
    name: 'Communication',
    components: {
        BannerSection,
    },
    data: () => {
        return {
            reInitBestsellersCarouselNeeded: false,
            reInitSpecialsCarouselNeeded: false
        }
    },
    mounted() {
        moduleCommunication.init()
        moduleDropdown.init()
        moduleEntertainment.init()
        moduleFavorites.init()
        moduleFilter.init()
        moduleGift.init()
        moduleModalBooking.init()
        moduleModalDiscount.init()
        modulePayment.init()
        moduleRegister.init()
        moduleTourism.init()
        moduleTourismProd.init()
        moduleTrip.init()
    },
    computed: {
        ...mapState({
            supplier_items: state => state.supplier.supplier_items,
            suppliers_products: state => state.supplier.suppliers_products,
            product_bestseller_tag_id: state => state.supplier.product_bestseller_tag_id,
            product_special_tag_id: state => state.supplier.product_special_tag_id,
        }),
        prepaidSuppliers() {
            let prepaidSuppliers = this.supplier_items && this.supplier_items.hasOwnProperty('prepaid') && Array.isArray(this.supplier_items.prepaid) && this.supplier_items.prepaid.length ? this.supplier_items.prepaid : [];
            prepaidSuppliers = prepaidSuppliers.filter(supplier => supplier.status === 1);

            return prepaidSuppliers;
        },
        prepaidSuppliersWithBestsellers() {
            let prepaidSuppliersWithBestsellers = []

            if (this.prepaidSuppliers && this.prepaidSuppliers.length && this.product_bestseller_tag_id) {
                for (let supplier of this.prepaidSuppliers) {
                    let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                        ? this.suppliers_products[supplier.id] : []
                    let supplier_bestsellers = []

                    if (supplier_products) {
                        for (let supplier_product_type in supplier_products) {
                            for (let supplier_product of supplier_products[supplier_product_type]) {
                                if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                    && supplier_product.tags.includes(this.product_bestseller_tag_id)) {
                                    supplier_bestsellers.push(supplier_product)
                                }
                            }
                        }
                    }

                    if (supplier_bestsellers.length) {
                        prepaidSuppliersWithBestsellers.push({...supplier, bestsellers: supplier_bestsellers})
                    }
                }
            }

            let obj = this
            obj.reInitBestsellersCarouselNeeded = true
            setTimeout(function () {
                if (obj.reInitBestsellersCarouselNeeded) {
                    obj.reInitBestsellersCarouselNeeded = false
                    obj.reInitBestsellersCarousel()
                }
            }, 500)

            return prepaidSuppliersWithBestsellers
        },
        prepaidSuppliersWithSpecials() {
            let prepaidSuppliersWithSpecials = []

            if (this.prepaidSuppliers && this.prepaidSuppliers.length && this.product_special_tag_id) {
                for (let supplier of this.prepaidSuppliers) {
                    let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                        ? this.suppliers_products[supplier.id] : []
                    let supplier_specials = []

                    if (supplier_products) {
                        for (let supplier_product_type in supplier_products) {
                            for (let supplier_product of supplier_products[supplier_product_type]) {
                                if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                    && supplier_product.tags.includes(this.product_special_tag_id)) {
                                    supplier_specials.push(supplier_product)
                                }
                            }
                        }
                    }

                    if (supplier_specials.length) {
                        prepaidSuppliersWithSpecials.push({...supplier, specials: supplier_specials})
                    }
                }
            }

            let obj = this
            obj.reInitSpecialsCarouselNeeded = true
            setTimeout(function () {
                if (obj.reInitSpecialsCarouselNeeded) {
                    obj.reInitSpecialsCarouselNeeded = false
                    obj.reInitSpecialsCarousel()
                }
            }, 500)

            return prepaidSuppliersWithSpecials
        }
    },
    created () {
        this.getAllSupplierItems({params: {filter: true}})
        this.getProductTags()
    },
    methods: {
        ...mapActions('supplier', {
            getAllSupplierItems: 'getAll',
            getProductTags: 'getProductTags',
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        reInitBestsellersCarousel() {
            $('.js-communication-bestsellers').each(function () {
                $(this).trigger('destroy.owl.carousel');
            })
            moduleCommunication.init()
        },
        reInitSpecialsCarousel() {
            $('.js-communication-special').each(function () {
                $(this).trigger('destroy.owl.carousel');
            })
            moduleCommunication.init()
        }
    }
}
</script>

<style lang="scss" scoped>
    .communication-new {
        flex-basis: 100%;
        padding: 0 90px 50px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
    }
    .communication__brand {
        height: 134px;
    }
    .brand__img {
        position: absolute;
        top: 0;
        height: calc(100% - 24px);
    }
    .communication__brand-text {
        width: 100%;
        height: 24px;
        bottom: 0;
        background: rgba(0,0,0,0.2);
    }

    .category-slider__product-name {
        max-width: 72%;
        height: 1.5em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .category-slider__link::before {
        top: 50%;
        transform: translateY(-50%);
    }
    .category-slider__link::after {
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        background-position: center;
    }

    .communication__brands {
        justify-content: unset;
        align-content: flex-start;
    }
    .communication__brand {
        flex-basis: calc((100% - 100px) / 6);
        margin: 10px;
        box-sizing: border-box;
    }
    .category-slider__img-wrapper {
        height: 80px;
        padding: 0 24px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        justify-content: space-between;
        .category-slider__img {
            width: 90px;
        }
    }
    @media screen and (min-width: 1366px) {
        .communication__brand:nth-child(n + 7) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(6n + 1) {
            margin-right: 0;
        }

        .communication__brand:nth-child(6n + 6) {
            margin-left: 0;
        }
    }
    @media screen and (max-width: 1365px) and (min-width: 768px) {
        .communication__brand {
            flex-basis: calc((100% - 60px) / 4);
        }
        .communication__brand:nth-child(n + 5) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(4n + 1) {
            margin-right: 0;
        }

        .communication__brand:nth-child(4n + 4) {
            margin-left: 0;
        }
    }
    @media screen and (max-width: 767px) {
        .communication-new {
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
            background-color: #DFE0E3;
        }
        .communication__brand {
            flex-basis: calc((100% - 40px) / 4);
            margin: 5px;
            height: 90px;
        }
        .communication__bestsellers,
        .communication__special {
            margin-top: 30px;
        }
        /* .communication__brand:nth-child(n + 4) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(3n + 1) {
            margin-right: 0;
        }

        .communication__brand:nth-child(3n + 3) {
            margin-left: 0;
        } */
    }
    .ltr-type {
        .category-slider__link::after {
            right: 10px !important;
        }
    }
</style>
